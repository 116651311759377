@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.cookie-form {
  $block-name: &;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: fixed;
  z-index: z(other);
  right: 39px;
  bottom: 39px;
  width: 270px;
  border: 1px solid $white-400;
  padding: 14px;
  transform: translateY(calc(100% + 140px));
  transition: transform $transition-time;
  border-radius: 14px;
  background: $white-100;
  box-shadow: 0 7px 85px -14px rgb(35 34 94 / 15%);

  @include mq($to: 1380px) {
    right: 29px;
  }

  @include mq($to: xl) {
    bottom: 29px;
  }

  @include mq($to: lg) {
    right: 19px;
    bottom: 19px;
  }

  @include mq($to: md) {
    right: 14px;
    bottom: 14px;
  }

  @include mq($to: sm) {
    flex-direction: row-reverse;
    left: 14px;
    width: auto;
    gap: 14px;
    padding: 10px 14px;
  }

  // transform: translateY(calc(100% + 40px));

  //   #{$block-name} {
  //   }

  &__button {
    .button {
      font-family: inherit;
    }
  }

  &__left {
    flex: 1 1 auto;

    @include mq($to: sm) {
      display: flex;
      align-items: center;
      gap: 14px;
    }
  }

  &__right {
    @include reset-text-indents;

    flex: 0 0 auto;
    margin-left: 14px;

    @include mq($to: sm) {
      width: 60px;
      height: 60px;
      margin-left: 0;
    }
  }

  &__text {
    color: $black-300;

    // font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-top: 0;
    margin-bottom: 12px;

    @include mq($to: sm) {
      margin-bottom: 0;
    }

    a {
      color: $purple-500;
      text-decoration-line: underline;
      transition: color $transition-time;

      &:hover {
        color: $color-secondary;
      }

      &:active {
        color: $purple-500;
      }
    }
  }

  &--is-visible {
    transform: translateY(0);
  }
}
